import axios from 'axios'
import { Message } from 'element-ui';
import router from '../router/index'
const service = axios.create({
	baseURL: 'https://baojiapc.server42.51scmb.com', //baseURL: process.env.VUE_APP_BASE_API,
	//baseURL: 'https://offer.hengliamerica.com',
	//baseURL: 'http://www.baojia.com',
	timeout: 15000,
	headers: {
		'Content-Type': 'application/json'
	},
})
service.interceptors.request.use(
	config => {
		let token = localStorage.getItem('token')
		if (token) {
			var tokenObj = JSON.parse(token)
			console.log(tokenObj)
			var notTime = Date.parse(new Date()) / 1000
			if (tokenObj && tokenObj.token && tokenObj.createtime && (notTime - tokenObj.createtime) < tokenObj.expires_in) {
				config.headers['token'] = tokenObj.token
			}
		}
		if (config.method === 'post') {
			config.data = {
				lang: 'en',
				...config.data
			}
		} else if (config.method === 'get') {
			config.params = {
				lang: 'en',
				...config.params
			}
		}

		return config
	},
	error => {
		return Promise.reject(error)
	}
)
service.interceptors.response.use(

	response => {
		const res = response.data
		if (res.code == 1000 || res.code == 401) {
			//登陆过期
			Message({
				type: 'warning',
				message: response.data.msg
			})
			localStorage.clear()
			router.push('/login')
		} else if (res.code == 1) {
			//操作成功
			return res
		} else {
			Message({
				type: 'warning',
				message: res.msg
			})
			return res
		}

		return res;
	},
	error => {
		console.log(error.request.status)
		if (error.request.status == 401) {
			Message({
				type: 'warning',
				message: 'Login status has expired'
			})
			localStorage.clear()
			router.push('/login')
		}
		return Promise.reject(error)
	}
)
export default service
