import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.less' // g全局css
import './utils/flexible.js'



//import ehecd from "@/utils/ehecd";
//Vue.prototype.ehecd = ehecd;

Vue.config.productionTip = false
Vue.use(ElementUI);

import Print from '@/utils/print'
Vue.use(Print)

//import config from './config';
// 附加方法到 Vue 原型上
//for (let key in config) {
  //Vue.prototype[key] = config[key];
//}


import request from "@/utils/request";
Vue.prototype.$request = request;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
