<template>
  <div class="container">
    <div class="nav_top">
      <div class="login_info" @click="toIndex">
        <img src="../assets/logo.png" class="logo" alt=""></img>
        <div class="title_text">HengliHydraulics Product Configuration</div>
      </div>
      <div class="nav_center" @click="dialogVisible = true">
        <span>Welcome to HengliHydraulics~</span>
        <span>{{userinfo.name}}</span>
      </div>
      <div class="nav_right">
        <div class="icon_box" @click="toChangePwd">
          <img src="@/assets/icon_pwd.png" class="icon" alt="">
          <div>Change password</div>
        </div>
        <div class="icon_box" @click="loginOut">
          <img src="@/assets/icon_loginOut.png" class="icon" alt="">
          <span class="logout">Log out</span>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 路由切换 -->
      <router-view />
    </div>
    <!-- 底部 -->
    <div class="footer_box"></div>
    <el-dialog title="Personal information" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form ref="ruleForm" :model="userinfo" :rules="rules" label-width="80px" label-position="top"
        class="demo-ruleForm">
        <el-form-item label="Name" prop="name" style="width: 100%">
          <el-input v-model.trim="userinfo.name" type="text" maxlength="50" class="re" placeholder="Please enter  name"
            auto-complete="on" />
        </el-form-item>
        <el-form-item label="Contact person" prop="nickname" style="width: 100%">
          <el-input v-model.trim="userinfo.nickname" type="text" maxlength="50" class="re" placeholder="Please enter contact person"
            auto-complete="on" />
        </el-form-item>
        <el-form-item label="Contact number" prop="mobile">
          <el-input v-model.trim="userinfo.mobile" type="number" maxlength="20" class="re" placeholder="Please enter contact number"
            auto-complete="on" />
        </el-form-item>
        <el-form-item label="Address" prop="address">
          <el-input v-model.trim="userinfo.address" type="text" maxlength="255" class="re" placeholder="Please enter address"
            auto-complete="on" />
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model.trim="userinfo.email" type="text" maxlength="150" class="re" placeholder="Please enter email"
            auto-complete="on" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button class="button" type="primary" @click="submitForm">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "layout",
  data () {
    return {
      active: 0,
      state: 0,
      scroll: false,
      dialogVisible: false,
      rules: {
        name: [
            { required: true, message: 'Please enter  name', trigger: 'blur' },
            {  max: 50, message: 'Name max length 50', trigger: 'blur' }
        ],
        nickname: [
            { required: true, message: 'Please enter contact person', trigger: 'blur' },
            {  max: 50, message: 'contact person max length 50', trigger: 'blur' }
        ],
        mobile: [
            { required: true, message: 'Please enter contact number', trigger: 'change' },
            {  max: 20, message: 'contact person max length 20', trigger: 'blur' }
        ],
        address: [
            { required: true, message: 'Please enter address', trigger: 'change' },
            {  max: 255, message: 'contact person max length 255', trigger: 'blur' }
        ],
        email: [
            { required: true, message: 'Please enter email', trigger: 'change' },
            {  max: 100, message: 'contact person max length 100', trigger: 'blur' }
        ]
      },
      userinfo:{}
    }
  },
  created () {
    var userinfo=localStorage.getItem("userinfo")
    if(userinfo){
      this.userinfo=JSON.parse(userinfo)
    }
  },
  mounted () {
  },
  methods: {
    handleClose () {
      //this.$refs.ruleForm.resetFields()
      this.dialogVisible = false
    },
    submitForm () {
      this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            
            this.$request.post('/api/user/profile',this.userinfo).then(res => {
            if(res.code==1){
              this.$message({
                    type: "success",
                    message: res.msg,
              });
              localStorage.setItem('userinfo',JSON.stringify(this.userinfo))
              this.dialogVisible = false
            }
          })


          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    toIndex () {
      this.$router.push({ name: 'Index' })
    },
    toChangePwd () {
      this.$router.push({ name: 'ChangePwd' })
    },
    /**
     * 退出登录
     */
    loginOut () {
      this.$confirm('Are you sure to log out?', 'warning', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          this.$request.post('/api/user/logout',{}).then(res => {
            if(res.code==1){
              this.$message({
                    type: "success",
                    message: res.msg,
              });
              localStorage.clear()
              this.$router.push("/login")
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });          
        });
    }
  },
};
</script>

<style lang="less">
.el-carousel__arrow {
  width: 70px !important;
  height: 70px !important;
  font-size: 28px !important;
}

.el-carousel__arrow--right {
  right: 100px !important;
}

.el-carousel__arrow--left {
  left: 100px !important;
}

.el-carousel__button {
  border-radius: 50% !important;
  width: 16px !important;
  height: 16px !important;
}
</style>

<style lang="less" scoped>
.logout{
  font-size: 18px;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav_top {
  height: 60px;
  background-color: #00479D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 360px;
  padding-right: 102px;
  color: #fff;

  .login_info {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .logo {
    width: 123px;
    height: 46px;
  }

  .title_text {
    font-size: 16px;
    padding-left: 16px;
  }

  .nav_center {
    font-size: 18px;
    cursor: pointer;  
  }

  .nav_right {
    display: flex;
    align-items: center;

    .icon_box {
      display: flex;
      align-items: center;
      margin-right: 74px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }

      .icon {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
    }
  }
}

.content {
  flex: 1;
  overflow-y: scroll;
}

.footer_box {
  height: 40px;
  background-color: #999999;
}

.button {
  background-color: #00479D;
}
</style>
