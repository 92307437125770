import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect:'/index',
    children:[
      { path: 'index', name: 'Index', component: () => import('../views/home/Index.vue') },
      { path: 'productInfo', name: 'ProductInfo', component: () => import('../views/home/productInfo.vue') },
      { path: 'confirmInfo', name: 'ConfirmInfo', component: () => import('../views/home/confirmInfo.vue') },
      { path: 'changePwd', name: 'ChangePwd', component: () => import('../views/home/changePwd.vue') },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
